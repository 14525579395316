import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@app/auth/auth.service';
import { UserData } from '@app/core/auth/user.model';
import { CpfUtils } from '@app/core/validators/cpf.validator';
import { PlanSummary } from '@app/home/statement.model';
import { StatementService } from '@app/home/statement.service';
import { format } from '@app/shared/date-fns';
import { PlanData } from '../plan.model';
import { PlanService } from '../plan.service';

@Component({
  selector: 'app-risk-page',
  templateUrl: './risk-page.component.html',
  styleUrls: ['./risk-page.component.scss']
})
export class RiskPageComponent implements OnInit {
  registration: string;
  initialDate: string;
  plan: PlanSummary;
  grantDate: string;
  userCpf: string;
  riskCoverage;
  showCardRisk: boolean;

  constructor(
    private router: Router,
    private planService: PlanService,
    private statementService: StatementService,
    private cpfUtils: CpfUtils,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.registration = this.router.url.split('/')[2];

    this.initialDate = this.planService.getPlanData(
      PlanData.InitialDate
    );
    this.populatePlanData();
    this.userCpf = this.cpfUtils.toDigits(
      this.authService.getUserData(UserData.Cpf)
    );

    this.planService
      .getRiskCoverage(
        this.registration,
        format({
          date: this.initialDate,
          dateFormat: 'yyyy-MM-dd'
        }),
        format({
          date: new Date(),
          dateFormat: 'yyyy-MM-dd'
        })
      )
      .subscribe((res) => {
        this.riskCoverage = res;
      });
    this.showCardRisk =
      !this.plan?.servicesToHide?.includes('CARD_RISCO');
  }

  public populatePlanData() {
    const data = this.statementService.getPlanData();
    this.plan = data.filter(
      (item) => item.registration === this.registration
    )[0];

    if (this.plan && this.plan.grantDate) {
      this.grantDate = this.plan.grantDate.slice(
        6,
        this.plan.grantDate.length
      );
    }
  }
}
