<ng-container>
  <div class="header__content">
    <app-sub-header
      [backButtonSettings]="backButtonSettings"
      [isCloseButtonVisible]="false"
    >
      <span class="title">Extrato</span>
      <span
        class="actions"
        [ngClass]="{ 'privacy-on': isPrivacyOn$ | async }"
      >
        <button
          mat-button
          class="privacy"
          (click)="togglePrivacyState()"
          [attr.title]="
            (isPrivacyOn$ | async)
              ? 'Botao exibir valores'
              : 'Botao ocultar valores'
          "
        >
          <mat-icon>visibility_off</mat-icon>
          <mat-icon>visibility</mat-icon>
          <span
            >{{
              (isPrivacyOn$ | async)
                ? 'Exibir '
                : 'Ocultar '
            }}valores</span
          >
        </button>
      </span>
    </app-sub-header>

    <header>
      <div class="top-content">
        <app-header-tile
          [title]="headerTile.balance.title"
          [tooltip]="headerTile.balance.tooltip"
          [value]="summaryBalance?.totalRelease"
        ></app-header-tile>
        <app-header-tile
          [title]="headerTile.balanceYield.title"
          [value]="balanceYield"
          [tooltip]="headerTile.balanceYield.tooltip"
        ></app-header-tile>
      </div>

      <div class="bottom-content">
        <app-processing-date
          [registration]="registration"
        ></app-processing-date>
      </div>
    </header>
  </div>

  <mat-accordion
    *ngIf="hasParcialBloq"
    class="plan-details"
  >
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <mat-icon>insert_chart</mat-icon>
          Detalhes do saldo
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div class="balance-summary">
        <div>
          <p class="balance-title">
            <mat-icon>attach_money</mat-icon>
            Saldo disponível
          </p>
          <p
            class="balance-amount"
            [appPrivacy]="
              plan?.totalSaldoDisponivel | currency
            "
          ></p>
        </div>
        <div>
          <p class="balance-title">
            <mat-icon>block</mat-icon>
            Saldo bloqueado
          </p>
          <p
            class="balance-amount"
            [appPrivacy]="
              plan?.totalSaldoBloqueado | currency
            "
          ></p>
        </div>
      </div>
      <div class="disclaimer">
        <p>
          <mat-icon>privacy_tip</mat-icon>
          Você optou por usar o seu investimento na
          Brasilprev como garantia para uma transação de
          crédito, devido a isto parte do seu saldo está
          bloqueado.
        </p>
      </div>
    </mat-expansion-panel>
  </mat-accordion>

  <mat-accordion
    class="plan-details"
    *ngIf="plan?.planType === 'EMPRESARIAL'"
  >
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <mat-icon>insert_chart</mat-icon>
          Detalhes do saldo
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div class="balance-summary" *ngIf="summaryBalance">
        <div>
          <p class="balance-title">
            <mat-icon>attach_money</mat-icon>
            Saldo participante
            <mat-icon
              [inline]="true"
              matTooltipPosition="after"
              matTooltip="Contribuições feitas pelo próprio participante, via descontos na folha de pagamento."
              aria-label="Mais informações"
            >
              help
            </mat-icon>
          </p>
          <p
            class="balance-amount"
            [appPrivacy]="
              summaryBalance?.totalReleaseParticipant
                | currency
            "
          ></p>
        </div>
        <div>
          <p class="balance-title">
            <mat-icon>luggage</mat-icon>
            Saldo empresa
            <mat-icon
              [inline]="true"
              matTooltipPosition="after"
              matTooltip="Contribuições feitas pela empresa em nome do participante."
              aria-label="Mais informações"
            >
              help
            </mat-icon>
          </p>
          <p
            class="balance-amount"
            [appPrivacy]="
              summaryBalance?.totalReleaseCompany | currency
            "
          ></p>
        </div>
        <div>
          <p class="balance-title">
            <mat-icon>star</mat-icon>
            Saldo suplementar
            <mat-icon
              [inline]="true"
              matTooltipPosition="after"
              matTooltip="Contribuições adicionais acumuladas por meio de aportes extras, livres de qualquer vínculo com o saldo empresa e possíveis penalidades."
              aria-label="Mais informações"
            >
              help
            </mat-icon>
          </p>
          <p
            class="balance-amount"
            [appPrivacy]="
              summaryBalance?.supplementaryReleases
                ?.totalRelease | currency
            "
          ></p>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</ng-container>

<!-- menu subpages statement -->
<nav class="statement-links">
  <div class="container">
    <p class="statement-links__title">
      Opções do seu extrato
    </p>

    <ul>
      <li
        *ngFor="let pageLink of pages"
        (click)="getPageUrl()"
      >
        <a
          [routerLink]="pageLink.link"
          [routerLinkActive]="['active']"
        >
          <img
            *ngIf="pageActive.indexOf(pageLink.link) < 0"
            src="../../assets/svg/{{ pageLink.icon }}.svg"
            alt="currency exchange icon"
          />
          <img
            *ngIf="pageActive.indexOf(pageLink.link) >= 0"
            src="../../assets/svg/{{
              pageLink.icon
            }}-active.svg"
            alt="currency exchange icon"
          />
          <p>{{ pageLink.name }}</p>
        </a>
      </li>
    </ul>
  </div>
</nav>
<!-- / menu subpages statement -->

<div class="container">
  <router-outlet></router-outlet>
</div>

<app-cross-selling-banner
  [feature]="
    crossSellingBannerFeatureEnum.EXTRA_CONTRIBUTION
  "
></app-cross-selling-banner>
<app-open-nps-survey-button></app-open-nps-survey-button>
