import { Component, OnInit } from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  Router
} from '@angular/router';
import { AuthService } from '@app/auth/auth.service';
import {
  PlanSummary,
  StatusPlanoPrevGarantia
} from '@app/home/statement.model';
import { StatementService } from '@app/home/statement.service';
import { BackButtonSettings } from '@app/shared/components/sub-header/sub-header.model';
import { format } from '@app/shared/date-fns';
import { headerTile } from '@app/shared/message';
import { first } from 'rxjs/operators';
import { PlanData, SummaryBalance } from './plan.model';
import { PlanService } from './plan.service';
import { CrossSellingBannerFeatureEnum } from '@app/shared/components/cross-selling-banner/cross-selling-banner.types';

@Component({
  selector: 'app-plan',
  templateUrl: './plan.component.html',
  styleUrls: ['./plan.component.scss']
})
export class PlanComponent implements OnInit {
  backButtonSettings: BackButtonSettings;
  isPrivacyOn$ = this.authService.isPrivacyOn();
  plan: PlanSummary;
  registration: string;
  headerTile = headerTile;
  initialDate: string;
  totalRelease: number;
  grantDate: string;
  showBalance = true;
  showFunds = false;
  pageActive: string;

  pages = [
    {
      name: 'Movimentações e rendimento',
      icon: 'currency-exchange',
      link: 'statement-summary'
    },
    {
      name: 'Fundos e rentabilidade',
      icon: 'content-copy',
      link: 'funds'
    },
    {
      name: 'Arquivos e documentos',
      icon: 'download',
      link: 'files'
    },
    {
      name: 'Cobertura de risco',
      icon: 'secutiry',
      link: 'risk'
    },
    {
      name: 'Dados do plano',
      icon: 'information',
      link: 'plan-data'
    }
  ];

  summaryBalance: SummaryBalance;
  balance;
  balanceYield;
  projectedIncome;
  projectedReservation;
  statusPlanoPrevGarantia = StatusPlanoPrevGarantia;
  hasParcialBloq: boolean;

  crossSellingBannerFeatureEnum =
    CrossSellingBannerFeatureEnum;

  constructor(
    private authService: AuthService,
    private statementService: StatementService,
    private route: ActivatedRoute,
    private router: Router,
    private planService: PlanService
  ) {
    this.backButtonSettings = {
      title: 'Voltar'
    };
  }

  ngOnInit(): void {
    this.pageActive = this.router.url;
    this.registration = this.route.snapshot.params.id;

    this.statementService.getPlansGraphql().subscribe(
      (res) => {
        this.statementService.setPlanData(res);
        this.populatePlanData();
      },
      (err) => {
        console.error(err);
      }
    );

    this.initialDate = this.planService.getPlanData(
      PlanData.InitialDate
    );
    this.balance = +this.planService.getPlanData(
      PlanData.Balance
    );
    this.balanceYield = +this.planService.getPlanData(
      PlanData.BalanceYield
    );

    this.planService
      .getSummaryBalance(
        this.registration,
        format({
          date: this.initialDate,
          dateFormat: 'yyyy-MM-dd'
        }),
        format({
          date: new Date(),
          dateFormat: 'yyyy-MM-dd'
        })
      )
      .subscribe(
        (summaryBalance: any) =>
          (this.totalRelease =
            summaryBalance.summaryBalanceStatement?.totalRelease)
      );

    this.planService
      .getIncomeReserveProjection(this.registration)
      .pipe(first())
      .subscribe(
        (res) => {
          this.projectedIncome = res.incomeValue;
          this.projectedReservation = res.reserveValue;
        },
        (error) => console.error(error)
      );

    this.planService
      .getSummaryBalance(
        this.registration,
        format({
          date: this.initialDate,
          dateFormat: 'yyyy-MM-dd'
        }),
        format({
          date: new Date(),
          dateFormat: 'yyyy-MM-dd'
        })
      )
      .pipe(first())
      .subscribe((summaryBalance) => {
        this.summaryBalance =
          summaryBalance?.summaryBalanceStatement;
        this.totalRelease =
          summaryBalance?.summaryBalanceStatement?.totalRelease;
      });

    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.getPageUrl();
      }
    });

    this.hasParcialBloq = this.getStatusPlan();
  }

  private getStatusPlan(): boolean {
    return (
      this.planService.getPlanData(PlanData.StatusPlano) ===
        this.statusPlanoPrevGarantia.statusPlano &&
      this.planService.getPlanData(
        PlanData.IdStatusPlanoDetalhe
      ) === this.statusPlanoPrevGarantia.bloqueioParcial
    );
  }

  public populatePlanData() {
    const data = this.statementService.getPlanData();
    this.plan = data.filter(
      (item) => item.registration === this.registration
    )[0];

    if (this.plan.grantDate) {
      this.grantDate = this.plan.grantDate.slice(
        6,
        this.plan.grantDate.length
      );
    }
  }

  togglePrivacyState(): void {
    this.authService.setPrivacyState(
      !this.authService.getPrivacyState()
    );
  }

  changePage() {
    this.showBalance = !this.showBalance;
    this.showFunds = !this.showFunds;
  }

  getPageUrl() {
    this.pageActive = this.router.url;
  }
}
