<h3>Cobertura de risco</h3>
<mat-card *ngIf="!riskCoverage?.length">
  <span
    >Não existem benefícios de risco contratados para esse
    plano</span
  >
</mat-card>

<div
  class="accordion-container"
  *ngFor="let item of riskCoverage"
>
  <mat-accordion [hideToggle]="true">
    <mat-expansion-panel #panel>
      <mat-expansion-panel-header>
        <div class="riskCovarage__content-header">
          <div class="content-header__title">
            <h2>
              {{ item.name }}
            </h2>
            <strong
              >Ver {{ panel.expanded ? 'menos' : 'mais' }}
              <span>detalhes</span></strong
            >
          </div>

          <div class="riskCoverage__line">
            <mat-icon
              aria-hidden="false"
              aria-label="error icon"
              >attach_money</mat-icon
            >
            <div>
              <strong>Valor da contribuição atual</strong>
              <p [appPrivacy]="item.amount | currency"></p>
            </div>
          </div>

          <div class="riskCoverage__line">
            <mat-icon
              aria-hidden="false"
              aria-label="error icon"
              >calendar_month</mat-icon
            >
            <div>
              <strong>Valor do benefício</strong>
              <p [appPrivacy]="item.prize | currency"></p>
            </div>
          </div>
        </div>
      </mat-expansion-panel-header>

      <div class="riskCoverage__line no-margin">
        <mat-icon
          aria-hidden="false"
          aria-label="error icon"
          >currency_exchange</mat-icon
        >
        <div>
          <strong>Periodicidade</strong>
          <p>{{ item?.frequencyContribution }}</p>
        </div>
      </div>

      <div
        class="riskCoverage__double-line"
        *ngFor="let beneficiaries of item?.beneficiaries"
      >
        <div class="riskCoverage__line">
          <mat-icon
            aria-hidden="false"
            aria-label="error icon"
            >person</mat-icon
          >
          <div>
            <strong>Beneficiário</strong>
            <p>{{ beneficiaries?.nomeBeneficiario }}</p>
          </div>
        </div>

        <div class="riskCoverage__line">
          <mat-icon
            aria-hidden="false"
            aria-label="error icon"
            >leaderboard</mat-icon
          >
          <div>
            <strong
              >Direito sobre valor da cobertura</strong
            >
            <p>{{ beneficiaries?.percentualRateio }}%</p>
          </div>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
